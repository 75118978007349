<template>
  <Progress v-if="isShown" v-bind:p="{ percent: false }" :cl="apg.clp(p.cl, 'fixed top-0 left-0 w-screen z-[999] bg-primary-600 h-2 rounded-full dark:bg-primary-500')" />
</template>

<script setup>
import { computed } from 'vue'
import { useAppeggio } from './useAppeggio'

const p = defineProps({ cl: { type: String } })
const apg = useAppeggio()
const isShown = computed(_ => apg.isLoading())
</script>
