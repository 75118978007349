<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)" role="group">
    <input ref="r" :id="i" type="hidden" data-tmp="true" v-model="m" @change="changed">
    <span :class="a.c(p.cl)">
      <slot></slot>
    </span>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, provide } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const m = defineModel()
provide('buttonGroupModel', m)
const i = p.p?.id ? p.p?.id : 'buttonGroup' + p.p?.aid
const r = ref()
</script>