
    <template>
      <div v-bind="a.p(p.p)" :class="[a.c(p.cl), m === undefined ? '-translate-x-full' : (!!m ? '' : '-translate-x-full')]">
        <slot></slot>
        <teleport v-if="!!m" to="body">
          <div drawer-backdrop="" class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30"></div>
        </teleport>
      </div>
    </template>
    <script setup>
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: {type: Object}, cl: {type: String} })
    const m = defineModel()
    </script>
  