
    <template>
      <aside v-bind="a.p(p.p)" :class="[a.c(p.cl), m === true ? w : undefined]">
        <slot v-if="m === true" name="rail"></slot>
        <slot v-else></slot>
      </aside>
    </template>
    <script setup>
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: {type: Object}, cl: {type: String} })
    const m = defineModel()
    const w = p.p?.railwidth ? 'w-[' + p.p.railwidth + ']' : 'w-[64px]'
    </script>
  