<template>
  <Card :cl="`${props.cl} flex-col py-2`">
    <div class="text-center">
      <Button :cl="`m-2 inline-flex items-center leading-none text-current bg-transparent border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 border-none ${props.btnCl}`" @click.native="playing ? pause() : play()">
        <Icon v-if="!playing || paused" :p="{ src: props.playIcon }" />
        <Icon v-else :p="{ src: props.pauseIcon }" />
      </Button>
      <Button :cl="`m-2 inline-flex items-center leading-none text-current bg-transparent border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 border-none ${props.btnCl}`" @click.native="stop()">
        <Icon :p="{ src: props.stopIcon }" />
      </Button>
      <Button :cl="`m-2 inline-flex items-center leading-none text-current bg-transparent border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 border-none ${props.btnCl}`" @click.native="mute()">
        <Icon v-if="!isMuted" :p="{ src: props.volumeHighIcon }" />
        <Icon v-else :p="{ src: props.volumeMuteIcon }" />
      </Button>
      <Button v-if="loaded && props.downloadable" :cl="`m-2 inline-flex items-center leading-none text-current bg-transparent border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 border-none ${props.btnCl}`" @click.native="loaded ? download() : reload()">
        <Icon :p="{ src: props.downloadIcon }" />
      </Button>
    </div>
    <div class="flex px-4 items-center gap-3 mt-3">
      <Icon :p="{ src: props.volumeHighIcon }" cl="text-2xl" />
      <RangeInput :p="{ min: 0, max: 1, step: 0.01 }" :cl="`w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 ${props.rangeCl}`" v-model="playerVolume" />
    </div>
    <div class="px-4 mt-3">
      <Progress :p="{ percent: percentage || 1, max: 100, noText: true }" :cl="`bg-primary-500 h-2 rounded-full text-xs ${props.progressCl}`" />
    </div>
    <p class="text-center p-3">
      {{ currentTime }} / {{ duration }}
    </p>
    <audio ref="refPlayer" @ended="props.ended" :src="theSrc" :type="props.srcType" />
  </Card>
</template>

<script setup>
import { onMounted, onBeforeUnmount, computed, ref } from 'vue'

const props = defineProps({
  cl: {
    type: String,
    default: ''
  },
  btnCl: {
    type: String,
    default: ''
  },
  rangeCl: {
    type: String,
    default: ''
  },
  progressCl: {
    type: String,
    default: ''
  },
  src: {
    type: String,
    default: null
  },
  srcType: {
    type: String,
    default: 'audio/mp3'
  },
  autoplay: {
    type: Boolean,
    default: false
  },
  bgColor: {
    type: String,
    default: null
  },
  fgColor: {
    type: String,
    default: null
  },
  downloadable: {
    type: Boolean,
    default: false
  },
  playIcon: {
    type: String,
    default: 'mdi:play'
  },
  pauseIcon: {
    type: String,
    default: 'mdi:pause'
  },
  stopIcon: {
    type: String,
    default: 'mdi:stop'
  },
  refreshIcon: {
    type: String,
    default: 'mdi:refresh'
  },
  downloadIcon: {
    type: String,
    default: 'mdi:download'
  },
  volumeHighIcon: {
    type: String,
    default: 'mdi:volume-high'
  },
  volumeMuteIcon: {
    type: String,
    default: 'mdi:volume-mute'
  },
})

import { useAppeggio } from './useAppeggio'

const app = useAppeggio()


const theSrc = ref('');
const firstPlay = ref(true)
const isMuted = ref(false)
const loaded = ref(false)
const playing = ref(false)
const paused = ref(false)
const percentage = ref(0)
const currentTime = ref('00:00:00')
const audio = ref(undefined)
const totalDuration = ref(0)
const playerVolume = ref(0.5)
const refPlayer = ref()
const shouldStartPlay = ref(false)

const formatTime = second => new Date(second * 1000).toISOString().substr(11, 8)

const duration = computed(() => {
  return audio.value ? formatTime(totalDuration.value) : ''
})

function setPosition() {
  audio.value.currentTime = parseInt(audio.value.duration / 100 * percentage.value);
}

function stop() {
  console.log('AAudio - stop')
  if (!loaded.value) return
  audio.value.pause()
  paused.value = true
  playing.value = false
  audio.value.currentTime = 0
}

function play() {
  console.log('AAudio - play')
  if (playing.value) return
  if (!loaded.value) {
    shouldStartPlay.value = true
    audio.value.load()
    return
  }
  audio.value.play().then(_ => playing.value = true)
  paused.value = false
}

function pause() {
  if (!loaded.value) return
  paused.value = !paused.value;
  (paused.value) ? audio.value.pause() : audio.value.play()
}

function download() {
  audio.value.pause()
  window.open(theSrc.value, 'download')
}

function mute() {
  if (!loaded.value) return
  isMuted.value = !isMuted.value
  audio.value.muted = isMuted.value
}

function reload() {
  audio.value.load();
}

function _handleLoaded() {
  if (audio.value.readyState >= 2) {
    if (audio.value.duration === Infinity) {
      audio.value.currentTime = 1e101;
      audio.value.ontimeupdate = () => {
        audio.value.ontimeupdate = () => {}
        audio.value.currentTime = 0
        totalDuration.value = parseInt(audio.value.duration)
        loaded.value = true;
      }
    } else {
      totalDuration.value = parseInt(audio.value.duration)
      loaded.value = true
    }

    if (props.autoplay || shouldStartPlay.value) audio.value.play()

  } else {
    throw new Error('Failed to load sound file')
  }
}

function _handlePlayingUI(e) {
  audio.value.volume = playerVolume.value
  percentage.value = audio.value.currentTime / audio.value.duration * 100
  currentTime.value = formatTime(audio.value.currentTime)
}

function _handlePlayPause(e) {
  if (e.type === 'play' && this.firstPlay) {
    audio.value.currentTime = 0;
    if (firstPlay.value) {
      firstPlay.value = false;
    }
  }
  if (e.type === 'pause' && paused.value === false && playing.value === false) {
    currentTime.value = '00:00:00'
  }
}

function _handleEnded() {
  paused.value = playing.value = false;
}

function init() {
  audio.value.addEventListener('timeupdate', _handlePlayingUI);
  audio.value.addEventListener('loadeddata', _handleLoaded);
  audio.value.addEventListener('pause', _handlePlayPause);
  audio.value.addEventListener('play', _handlePlayPause);
  audio.value.addEventListener('ended', _handleEnded);
}

onMounted(() => {
  audio.value = refPlayer.value;
  app.videoFileSign(props.src.substring(props.src.lastIndexOf('/') + 1))
    .then(val => {
      theSrc.value = val.get('url');
      init();
    }).catch(err => {
      console.error(err)
    })
})

onBeforeUnmount(() => {
  audio.value.removeEventListener('timeupdate', _handlePlayingUI)
  audio.value.removeEventListener('loadeddata', _handleLoaded)
  audio.value.removeEventListener('pause', _handlePlayPause)
  audio.value.removeEventListener('play', _handlePlayPause)
  audio.value.removeEventListener('ended', _handleEnded);
})

</script>
