<template>
  <footer v-bind="a.p(p.p)" :class="[ backgrounds, a.c(p.cl), sticky ]">
    <slot></slot>
  </footer>
</template>
<script setup>
import { useBackgroundImage } from '../../composables/use-background-image.js'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const { backgrounds } = useBackgroundImage(p.p)
const sticky = p.p?.sticky === true ? 'fixed bottom-0 z-20 w-full' : undefined 
</script>