
    <template>
      <ol v-bind="a.p(p.p)" :class="[a.c(p.cl), !p.p?.vertical ? 'border-s mx-4 md:border-t md:border-s-0 flex flex-col md:flex-row md:items-start md:mx-0 md:my-4' : 'border-s mx-4']" role="tablist">
        <slot></slot>
      </ol>
    </template>
    <script setup>
    import { computed, provide } from 'vue'
    import {useAppeggio} from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({p: {type: Object}, cl: {type: String}})
    const m = defineModel()
    const stepperVertical = computed(_ => p.p?.vertical === true)
    provide('stepperVertical', stepperVertical)
    provide('modelValue', m)
    </script>
  