
    <template>
      <div v-bind="a.p(p.p)" :class="a.c(p.cl)" @click="click">
        <input ref="cbox" type="checkbox" style="visibility: hidden">
        <slot></slot>
      </div>
    </template>
    <script setup>
    import {ref} from 'vue'
    import {useAppeggio} from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({p: {type: Object}, cl: {type: String}})
    const cbox = ref()
    const click = $e => {
      if ($e.handled) return;
      $e.handled = true
      cbox.value.click()
      $e.stopPropagation()
    }
    </script>
  