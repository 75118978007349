<template>
  <ul ref="el" v-bind="apg.p(p.p)" :class="apg.c(p.cl)">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </ul>
</template>
<script setup>
import { ref, onMounted, onUpdated, provide, inject } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits(['update:modelValue'])
const apg = useAppeggio()
const m = defineModel()
const upperModel = inject('modelValue', undefined)
const itemLabelSetter = inject('itemLabel', () => {})
provide('modelValue', upperModel || m)
const el = ref()
onMounted(_ => {
  if (upperModel !== undefined) {
    if (!el.value.querySelector('[data-active="true"]')) {
      upperModel.value = null
      itemLabelSetter('')
    }
  }
})
</script>