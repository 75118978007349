
    <template>
      <a v-if="h" v-bind="a.p(p.p)" :class="a.c(p.cl)">
        <slot name="prepend"></slot>
        <slot></slot>
        <slot name="append"></slot>
      </a>
      <span v-else v-bind="a.p(p.p)" :class="a.c(p.cl)">
        <slot name="prepend"></slot>
        <slot></slot>
        <slot name="append"></slot>
      </span>
    </template>
    <script setup>
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({p: {type: Object}, cl: {type: String}})
    const h = p.p?.type === 'submit' || p.p?.modal || p.p?.ddid ? undefined : p.p?.href
    </script>
  