
    <template>
      <div v-bind="a.p(p.p)" :class="a.c(p.cl)" @click="copy">
        <slot v-if="!hasCopied">
          Click to copy
        </slot>
        <slot v-else name="Alternate">
          Copied!
        </slot>
      </div>
    </template>
    <script setup>
    import { ref } from 'vue'
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: { type: Object }, cl: { type: String } })
    const hasCopied = ref(false)
    async function copy() {
      await navigator.clipboard.writeText(p.p?.copyText || '')
      hasCopied.value = true
      setTimeout(_ => hasCopied.value = false, p.p?.copyTimeout || 2000)
    }
    </script>
  