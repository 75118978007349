<template>
  <h2 :id="i">
    <button type="button" v-bind="a.p(m(p.p))" :class="a.c(p.cl)" :data-accordion-target="dat" :data-active="elmg === i" @click="toggleElms()">
      <slot name="prepend"></slot>
      <slot></slot>
      <div class="grow" />
      <template v-if="$slots.append">
        <slot name="append"></slot>
      </template>
      <template v-else>
        <Icon :p="{ src: 'mdi-chevron-down' }" class="rotating-icon" />
      </template>
    </button>
  </h2>
</template>
<script setup>
import { onMounted, inject } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id !== undefined ? 'ac' + p.p.id : 'accordionHeader' + p.p.aid
const m = v => Object.assign({}, v, { id: undefined })
const dat = '#' + i + '-body'
const elmg = inject('a-get-accitem', false)
const elms = inject('a-set-accitem', false)
function toggleElms() {
  if (elms) elms(elmg?.value === i ? '' : i)
}
onMounted(_ => {
  if (elmg !== false && elmg.value === i) {
    let el = document.getElementById(i + '-body')
    if (el) el.classList.remove('hidden')
  }
})
</script>