<template>
  <div :id="id" :alt="alt" :tabindex="tabindex" class="relative overflow-hidden" :class="[`aspect-[${aspectRatio}]`]">
    <template v-if="showCover">
      <Image :p="{ src: app.a(cover) }" cl="w-full h-full object-cover rounded-0">
        <Card
          :p="{ href: false }"
          cl="w-full h-full flex items-center justify-center bg-[#00000066] cursor-pointer rounded-none"
          @click="onCoverClick">
          <Button
            :p="{ href: false }"
            cl="p-2 text-6xl rounded-full text-white bg-primary-500">
            <Icon :p="{ src: 'mdi:play' }" size="2xl" />
          </Button>
        </Card>
      </Image>
    </template>
    <template v-else>

      <video
        ref="refVideo"
        :controls="controls"
        :loop="loop"
        :autoplay="loop"
        :muted="loop"
        playsinline
        class="h-full w-full"
        :class="{
          'object-contain': pr.contain,
          'object-cover': !pr.contain,
        }">

        <source :src="thesrc" />

      </video>

      <div class="p-0 m-0 absolute top-0 left-0 w-full h-full pointer-events-none">
        <slot />
      </div>

    </template>
    <div class="a-video-stopper absolute top-0 right-0 w-1/2 h-1/2" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useAppeggio } from './useAppeggio'

const app = useAppeggio()

const pr = defineProps({
  id: { type: String },
  alt: { type: String },
  tabindex: { type: Number },
  src: { type: String },
  loop: { type: Boolean },
  controls: { type: Boolean },
  contain: { type: Boolean },
  cover: { type: String },
  aspectRatio: { type: String },
})

const thesrc = ref(false)

const loop = computed(() => {
  return pr.loop || false
})

const controls = computed(() => {
  return pr.controls !== undefined ? pr.controls : loop.value === true ? false : pr.controls || true
})

const refVideo = ref();
const showCover = ref(!!pr.cover);

async function onCoverClick(e) {
  showCover.value = false;
  await nextTick();
  refVideo.value.play();
}

onMounted(() => {
  thesrc.value = pr.src
  // app.videoFileSign(pr.src.substring(pr.src.lastIndexOf('/') + 1))
  //     .then(val => {
  //       thesrc.value = val.get('url')
  //     }).catch(err => {
  //       console.error(err)
  //     })
})
</script>
