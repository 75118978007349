
    <template>
      <div v-bind="a.p(p.p)" :class="a.c(p.cl)" data-carousel="slide">
        <slot name="prepend"></slot>
        <slot></slot>
        <slot name="append"></slot>
      </div>
    </template>
    <script setup>
    import { onMounted } from 'vue'
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: { type: Object }, cl: { type: String } })
    onMounted(_ => a.fbInit('carousels'))
    </script>
  