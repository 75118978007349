// https://vike.dev/onRenderClient
import { createApp } from './app'
import { getFresh } from './utils'
import Cookies from 'js-cookie'

export { onRenderClient, clearPages }

const reloadOnNewBuild = n => {
  let current = Cookies.get('buildNumber')
  Cookies.set('buildNumber', n)
  if (current === undefined || current === 'undefined' || current === `${n}` || current === n) return false;
  window.location.reload()
}

const clearPages = function() {
  pageCache = {}
}

let server = false
let root = false
let pageCache = {}

async function makeFresh(pageContext, initial) {
  if (initial) pageCache[pageContext.urlPathname] = initial;
  pageCache[pageContext.urlPathname] = await getFresh(server, root, pageContext.urlPathname, pageCache[pageContext.urlPathname] || { ETag: false, tpl: false, tpljs: false, title: '' })
  pageContext.ETag = pageCache[pageContext.urlPathname].ETag
  pageContext.tpl = pageCache[pageContext.urlPathname].tpl
  pageContext.tpljs = pageCache[pageContext.urlPathname].tpljs
  pageContext.title = pageCache[pageContext.urlPathname].title
}

let app
async function onRenderClient(pageContext) {
  if (!app) {
    if (!reloadOnNewBuild(pageContext.buildNumber)) {
      let { host, appId } = pageContext
      server = host
      root = appId
      await makeFresh(pageContext, { ETag: pageContext.ETag, tpl: pageContext.tpl, tpljs: false, title: document.title })
      app = await createApp(pageContext)
      app.mount('#app')
      document.body.style.opacity = 1;
    }
  } else {
    await makeFresh(pageContext)
    document.title = pageContext.title !== undefined ? pageContext.title : ''
    app.changePage(pageContext)
  }
  // document.title = getPageTitle(pageContext)
}
