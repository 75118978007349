
    <template>
      <span v-bind="a.p(p.p)" :class="[a.c(p.cl), timelineVertical ? 'top-0 -start-4' : 'top-0 -start-4 md:!-top-4 md:start-0', timelineValue === m ? '!text-white !bg-green-500' : '']">
        <slot></slot>
      </span>
    </template>
    <script setup>
    import { inject } from 'vue'
    import {useAppeggio} from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({p: {type: Object}, cl: {type: String}})
    const timelineVertical = inject('timelineVertical', false)
    const m = inject('modelValue')
    const timelineValue = inject('timelineValue', undefined)
    </script>
  