<template>
  <div v-bind="a.p(p.p)" :class="[a.c(p.cl), m === undefined ? 'hidden' : !!m ? '' : 'hidden']" @click.self="m = false">
    <div class="relative p-4 w-full max-h-full">
      <slot></slot>
    </div>
    <teleport v-if="!!m" to="body">
      <div class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40" @click="m = false"></div>
    </teleport>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: {type: Object}, cl: {type: String} })
const m = defineModel()
onMounted(_ => a.fbInit('modals'))
</script>