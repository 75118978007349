<template>
  <div v-bind="apg.p(p.p)" :id="p.p?.id + 'Dd'" :class="apg.c(p.cl)" @keydown="keydown">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
    <input v-if="m !== undefined" ref="el" :id="p.p?.id" type="hidden" v-model="m">
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated, provide, inject } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
import { getElByAttr } from '../../utils.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const apg = useAppeggio()
const ddp = p.p?.id ? p.p.id : 'dropdown' + p.p?.aid
const m = defineModel()
const upperModel = inject('modelValue', undefined)
provide('modelValue', upperModel || m)
provide('listItemSelectHandler', (v) => {
  click();
})
const click = $e => {
  if ($e?.handled) return;
  if ((p.p?.closeonclick !== false) && ddp) {
    let el = getElByAttr('ddid', ddp) || getElByAttr('id', ddp)
    if (el) {
      if ($e) $e.handled = true
      el.click()
    }
  }
} 
const keydown = $e => {
  if ($e.handled) return;
  if ($e.key === 'Escape') {
    $e.handled = true
    click()
  }
}
onMounted(_ => apg.fbInit('dropdowns'))
</script>