<template>
  <div :class="a.c(p.cl)" :style="s()">
    <span v-if="!p.p?.noText">
      {{v()}}
    </span>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const i = _ => p.p?.pcent ? p.p.pcent : p.p?.percent ? p.p.percent : false
const v = _ => i() ? Math.round(Math.max(1, i() || 1)) + '%' : '' 
const s = _ => v() !== '' ? 'width: ' + Math.max(1, i() || 100) + '%' : 'animation-name: indeterminate-width; animation-duration: 3000ms; animation-iteration-count: infinite; animation-timing-function: ease-in-out;'
</script>