<script setup>

import { ref, watch, computed } from 'vue';
import { getColors } from 'theme-colors';


/* appeggio */

import { useAppeggio } from '../../app/useAppeggio.js';
const app = useAppeggio();


/* interface */

const emit = defineEmits([
  'update:palette',
]);

const props = defineProps({ id: { type: String } })

const modelValue = defineModel();


/* palette */

const paletteBtnRef = ref();

const PALETTE = [
  {
    name: 'Amber',
    colors: [ '#fffbeb', '#fef3c7', '#fde68a', '#fcd34d', '#fbbf24', '#f59e0b', '#d97706', '#b45309', '#92400e', '#78350f', '#451a03' ],
  },
  {
    name: 'Blue',
    colors: [ '#eff6ff', '#dbeafe', '#bfdbfe', '#93c5fd', '#60a5fa', '#3b82f6', '#2563eb', '#1d4ed8', '#1e40af', '#1e3a8a', '#172554' ],
  },
  {
    name: 'Cyan',
    colors: [ '#ecfeff', '#cffafe', '#a5f3fc', '#67e8f9', '#22d3ee', '#06b6d4', '#0891b2', '#0e7490', '#155e75', '#164e63', '#083344' ],
  },
  {
    name: 'Emerald',
    colors: [ '#ecfdf5', '#d1fae5', '#a7f3d0', '#6ee7b7', '#34d399', '#10b981', '#059669', '#047857', '#065f46', '#064e3b', '#022c22' ],
  },
  {
    name: 'Fuchsia',
    colors: [ '#fdf4ff', '#fae8ff', '#f5d0fe', '#f0abfc', '#e879f9', '#d946ef', '#c026d3', '#a21caf', '#86198f', '#701a75', '#4a044e' ],
  },
  {
    name: 'Gray',
    colors: [ '#f9fafb', '#f3f4f6', '#e5e7eb', '#d1d5db', '#9ca3af', '#6b7280', '#4b5563', '#374151', '#1f2937', '#111827', '#030712' ],
  },
  {
    name: 'Green',
    colors: [ '#f0fdf4', '#dcfce7', '#bbf7d0', '#86efac', '#4ade80', '#22c55e', '#16a34a', '#15803d', '#166534', '#14532d', '#052e16' ],
  },
  {
    name: 'Indigo',
    colors: [ '#eef2ff', '#e0e7ff', '#c7d2fe', '#a5b4fc', '#818cf8', '#6366f1', '#4f46e5', '#4338ca', '#3730a3', '#312e81', '#1e1b4b' ],
  },
  {
    name: 'Lime',
    colors: [ '#f7fee7', '#ecfccb', '#d9f99d', '#bef264', '#a3e635', '#84cc16', '#65a30d', '#4d7c0f', '#3f6212', '#365314', '#1a2e05' ],
  },
  {
    name: 'Neutral',
    colors: [ '#fafafa', '#f5f5f5', '#e5e5e5', '#d4d4d4', '#a3a3a3', '#737373', '#525252', '#404040', '#262626', '#171717', '#0a0a0a' ],
  },
  {
    name: 'Orange',
    colors: [ '#fff7ed', '#ffedd5', '#fed7aa', '#fdba74', '#fb923c', '#f97316', '#ea580c', '#c2410c', '#9a3412', '#7c2d12', '#431407' ],
  },
  {
    name: 'Red',
    colors: [ '#fef2f2', '#fee2e2', '#fecaca', '#fca5a5', '#f87171', '#ef4444', '#dc2626', '#b91c1c', '#991b1b', '#7f1d1d', '#450a0a' ],
  },
  {
    name: 'Rose',
    colors: [ '#fff1f2', '#ffe4e6', '#fecdd3', '#fda4af', '#fb7185', '#f43f5e', '#e11d48', '#be123c', '#9f1239', '#881337', '#4c0519' ],
  },
  {
    name: 'Pink',
    colors: [ '#fdf2f8', '#fce7f3', '#fbcfe8', '#f9a8d4', '#f472b6', '#ec4899', '#db2777', '#be185d', '#9d174d', '#831843', '#500724' ],
  },
  {
    name: 'Purple',
    colors: [ '#faf5ff', '#f3e8ff', '#e9d5ff', '#d8b4fe', '#c084fc', '#a855f7', '#9333ea', '#7e22ce', '#6b21a8', '#581c87', '#3b0764' ],
  },
  {
    name: 'Sky',
    colors: [ '#f0f9ff', '#e0f2fe', '#bae6fd', '#7dd3fc', '#38bdf8', '#0ea5e9', '#0284c7', '#0369a1', '#075985', '#0c4a6e', '#082f49' ],
  },
  {
    name: 'Slate',
    colors: [ '#f8fafc', '#f1f5f9', '#e2e8f0', '#cbd5e1', '#94a3b8', '#64748b', '#475569', '#334155', '#1e293b', '#0f172a', '#020617' ],
  },
  {
    name: 'Stone',
    colors: [ '#fafaf9', '#f5f5f4', '#e7e5e4', '#d6d3d1', '#a8a29e', '#78716c', '#57534e', '#44403c', '#292524', '#1c1917', '#0c0a09' ],
  },
  {
    name: 'Teal',
    colors: [ '#f0fdfa', '#ccfbf1', '#99f6e4', '#5eead4', '#2dd4bf', '#14b8a6', '#0d9488', '#0f766e', '#115e59', '#134e4a', '#042f2e' ],
  },
  {
    name: 'Violet',
    colors: [ '#f5f3ff', '#ede9fe', '#ddd6fe', '#c4b5fd', '#a78bfa', '#8b5cf6', '#7c3aed', '#6d28d9', '#5b21b6', '#4c1d95', '#2e1065' ],
  },
  {
    name: 'Yellow',
    colors: [ '#fefce8', '#fef9c3', '#fef08a', '#fde047', '#facc15', '#eab308', '#ca8a04', '#a16207', '#854d0e', '#713f12', '#422006' ],
  },
  {
    name: 'Zinc',
    colors: [ '#fafafa', '#f4f4f5', '#e4e4e7', '#d4d4d8', '#a1a1aa', '#71717a', '#52525b', '#3f3f46', '#27272a', '#18181b', '#09090b' ],
  },
].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));


/* color */

const enteredColor = ref(modelValue.value || '#b31414');
const currentColor = ref(modelValue.value || '#b31414');

watch(enteredColor, (newVal) => {
  if (newVal.match(/^#*[0-9a-fA-F]{6}$/)) {

    const color = '#' + newVal.slice(-6);

    enteredColor.value = color;
    currentColor.value = color;
    modelValue.value = color;

  }
});

watch(currentColor, (newVal) => {
  if (currentColor.value !== enteredColor.value) {
    enteredColor.value = newVal;
  }
});


const colorPalette = computed(() => {
  const palette = getColors(currentColor.value.toUpperCase());
  delete palette[950];
  return palette;
});

watch(colorPalette, (newVal) => {
  emit('update:palette', newVal);
});


/* states */

const selectedTab = ref('tab1');
const selectedAccordion = ref('acaccordion2');

</script>


<template>
  <div class="text-black">

    <div>
      <p>
        Select Color
      </p>
      <div class="flex items-center justify-center max-w-sm gap-2 border rounded-full overflow-hidden py-1 px-2">
        <div class="w-8 h-8 rounded-full overflow-hidden relative shrink-0">
          <input type="color" class="w-16 h-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer" v-model="currentColor" />
        </div>
        <input type="text" class="w-0 grow border-none !rounded-r-full" v-model="enteredColor" />
        <Button ref="paletteBtnRef" :p="{ ddid: `${props.id}-palette-dropdown`, href: false }" cl="flex items-center text-sm border rounded-full p-2 ps-3 bg-slate-100" >
          Palette
        </Button>
      </div>
    </div>

    <Dropdown :p="{ id: `${props.id}-palette-dropdown` }" cl="z-[10000] hidden bg-white rounded-lg shadow-lg !shadow-black/40 border">
      <div class="flex flex-col gap-3 p-3 h-[512px] overflow-y-auto">
        <div v-for="theme in PALETTE" :key="theme.name">
          <p>
            {{ theme.name }}
          </p>
          <div class="grid grid-cols-10 gap-1">
            <div
              v-for="i in 10" :key="i - 1"
              class="rounded-lg p-2 text-sm flex flex-col items-center justify-center cursor-pointer"
              :style="{ 'background-color': theme.colors[i], color: i >= 5 ? '#fff' : '#000' }"
              @click="currentColor = theme.colors[i]; paletteBtnRef.$el.click()">
              {{ Math.max(50, (i - 1) * 100) }}
              <br />
              <span class="text-xs">
                {{ theme.colors[i] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Dropdown>

    <p class="mt-6">
      Generated Variations
    </p>

    <div class="grid grid-cols-10 gap-1 w-full mt-2">
      <div
        v-for="i in 10" :key="i"
        class="rounded-lg p-4 aspect-square flex flex-col items-center justify-center"
        :style="{
          'background-color': colorPalette[Math.max(50, (i - 1) * 100)],
          'color': i >= 5 ? '#fff' : '#000',
        }">
        <Icon :p="{ src: i === 6 ? 'mdi:lock-outline' : 'mdi:none' }" class="mb-1" />
        <span>
          {{ Math.max(50, (i - 1) * 100) }}
        </span>
        <span>
          {{ colorPalette[Math.max(50, (i - 1) * 100)] }}
        </span>
      </div>
    </div>

    <p class="mt-6">
      Examples
    </p>

    <div class="grid grid-cols-3 gap-8 w-full mt-2">

      <div class="rounded-xl p-4 flex flex-col items-start border relative overflow-hidden">
        <p class="shrink-0 z-10">
          Customers
        </p>
        <div class="h-0 grow w-full flex flex-col items-start justify-center p-4 z-10">
          <div class="flex text-6xl items-start gap-1 font-semibold">
            1,553
            <Icon :p="{ src: 'mdi:arrow-top-right' }" class="text-3xl font-regular" :style="`color: ${colorPalette['700']};`" />
          </div>
          <p class="mt-2">
            New customers in past 30 days
          </p>
        </div>
        <div
          class="absolute -top-48 left-1/2 -translate-x-1/2 rounded-full w-96 h-96 blur-3xl mix-blend"
          :style="`background-color: ${colorPalette['400']};`"
        />
        <div
          class="absolute -top-4 -left-16 -translate-y-1/2 rounded-full w-96 h-96 blur-3xl mix-blend opacity"
          style="top: 24rem; left: -14rem;"
          :style="`background-color: ${colorPalette['200']};`"
        />
      </div>

      <div class="rounded-xl flex flex-col items-start border relative overflow-hidden">
        <p class="shrink-0 p-4 !pb-0">
          Revenue
        </p>
        <p class="shrink-0 text-2xl mt-2 p-4 !pt-0">
          $12,543
        </p>
        <div class="h-0 grow w-full flex flex-col items-start justify-center z-10">
          <div class="h-0 grow w-full grid grid-cols-6 justify-between items-end border-b">
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[70%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[90%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[50%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[70%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[40%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[30%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[90%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[70%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[55%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[80%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
            <div class="h-full flex items-end justify-center gap-1">
              <div class="h-[50%] w-[12px]" :style="`background-color: ${colorPalette['500']};`" />
              <div class="h-[45%] w-[12px]" :style="`background-color: ${colorPalette['600']};`" />
            </div>
          </div>
          <div class="w-full grid grid-cols-6 justify-between text-center text-xs opacity-75 mt-1 pb-2">
            <span>
              Jan
            </span>
            <span>
              Feb
            </span>
            <span>
              Mar
            </span>
            <span>
              Apr
            </span>
            <span>
              May
            </span>
            <span>
              Jun
            </span>
          </div>
        </div>
      </div>

      <div class="rounded-xl p-4 flex flex-col items-start border relative overflow-hidden">
        <p class="mb-6">
          Today
        </p>
        <div class="p-3 border-l-8 rounded-lg w-full relative" :style="`border-color: ${colorPalette['600']}; background-color: ${colorPalette['200']};`">
          <p class="text-sm">
            Application design meeting
          </p>
          <p class="text-xs">
            9 - 10 AM
          </p>
          <Icon :p="{ src: 'mdi:dots-horizontal' }" class="absolute top-3 right-2" :style="`color: ${colorPalette['800']};`" />
          <div class="flex items-center gap-1 -space-x-3 mt-3">
            <img :src="`${app.a('/m/picsum-l.jpg')}`" class="rounded-full border-2 border-white" />
            <img :src="`${app.a('/m/picsum-32.jpg')}`" class="rounded-full border-2 border-white" />
            <img :src="`${app.a('/m/picsum-f.jpg')}`" class="rounded-full border-2 border-white" />
            <img :src="`${app.a('/m/picsum-m.jpg')}`" class="rounded-full border-2 border-white" />
            <img :src="`${app.a('/m/picsum-m2.jpg')}`" class="rounded-full border-2 border-white" />
          </div>
        </div>
        <div class="p-3 border-l-8 rounded-lg w-full relative mt-3" :style="`border-color: ${colorPalette['600']}; background-color: ${colorPalette['200']};`">
          <p class="text-sm">
            Lunch
          </p>
          <p class="text-xs">
            1 - 2 PM
          </p>
          <Icon :p="{ src: 'mdi:dots-horizontal' }" class="absolute top-3 right-2" :style="`color: ${colorPalette['800']};`" />
        </div>
        <div class="p-3 border-l-8 rounded-lg w-full relative mt-3" :style="`border-color: ${colorPalette['600']}; background-color: ${colorPalette['200']};`">
          <p class="text-sm">
            Application review
          </p>
          <p class="text-xs">
            3 - 4 PM
          </p>
          <Icon :p="{ src: 'mdi:dots-horizontal' }" class="absolute top-3 right-2" :style="`color: ${colorPalette['800']};`" />
          <div class="flex items-center gap-1 -space-x-3 mt-3">
            <img :src="`${app.a('/m/picsum-l.jpg')}`" class="rounded-full border-2 border-white" />
            <img :src="`${app.a('/m/picsum-f.jpg')}`" class="rounded-full border-2 border-white" />
          </div>
        </div>
      </div>

      <div
        class="rounded-xl flex flex-col items-start border relative overflow-hidden h-96 bg-cover bg-center"
        :style="`background-image: url(${app.a('/m/themer-img-1.webp')});`">
        <div
          class="absolute inset-0"
          :style="`background-image: linear-gradient(transparent 0%, ${colorPalette['800']} 100%);`">
          <div class="h-full flex">
            <div
              class="leading-none p-6 rounded-2xl mt-auto mb-2 text-4xl font-semibold drop-shadow-sm tracking-tight"
              :style="`color: ${colorPalette['50']};`">
              Create <br />
              <span :style="`color: ${colorPalette['300']};`">
                applications
                <br />
              </span>
              in seconds.
            </div>
          </div>
        </div>
      </div>

      <div
        class="rounded-xl flex flex-col items-start border relative overflow-hidden h-96 bg-cover bg-center">
        <div
          class="absolute inset-0"
          :style="`background-color: ${colorPalette['300']};`">
          <div
            class="absolute rounded-full z-0"
            :style="`width: 26rem; height: 26rem; top: 2rem; right: 6rem; background-position: 25% -83%; background-size: 90%; transform: translate(50%, -50%); background-image: url(${app.a('/m/themer-img-2.webp')});`"
          />
          <div
            class="absolute rounded-full"
            style="width: 22rem; height: 22rem; top: 2rem; right: 6rem; border: 25px solid rgb(158, 254, 255); transform: translate(50%, -50%);"
            :style="`border-color: ${colorPalette['300']};`"
          />
          <div
            class="absolute rounded-full"
            style="width: 14rem; height: 14rem; top: 2rem; right: 6rem; border: 25px solid rgb(158, 254, 255); transform: translate(50%, -50%);"
            :style="`border-color: ${colorPalette['300']};`"
          />
          <div class="h-full flex">
            <div class="leading-none p-6 rounded-2xl mt-auto mb-2 text-4xl font-semibold drop-shadow-sm tracking-tight" :style="`color: ${colorPalette['900']};`">
              Create <br />
              <span :style="`color: ${colorPalette['600']};`">
                applications
                <br />
              </span>
              in seconds.
            </div>
          </div>
        </div>
      </div>

      <div
        class="rounded-xl flex flex-col items-start border relative overflow-hidden h-96 p-2 bg-cover bg-center"
        :style="`background-image: url(${app.a('/m/themer-img-3.webp')})`">
        <div class="h-full w-full flex">
          <div
            class="leading-none p-4 rounded-2xl mt-auto text-4xl font-semibold tracking-tight text-white border w-full bg-opacity-80 backdrop-filter backdrop-blur-sm"
            :style="`background-color: ${colorPalette['300']}CC; border-color: ${colorPalette['500']};`">
            Create<br> applications<br> in seconds.
          </div>
        </div>
      </div>

      <div class="rounded-xl p-4 flex flex-col items-start border relative overflow-hidden">
        <p class="shrink-0 z-10">
          Buttons
        </p>
        <div class="w-full">
          <div class="w-full py-4">
            <Button :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="block w-full px-5 py-2.5 rounded-xl font-medium text-white focus:ring-4 border-none">Default</Button>
          </div>
          <div class="w-full pb-4">
            <Button :class="`bg-[${colorPalette[600]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="block w-full px-5 py-2.5 rounded-xl font-medium text-white focus:ring-4 border-none">Hover</Button>
          </div>
          <div class="w-full pb-4">
            <Button :p="{ disabled: true }" :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="block w-full px- px-5 py-2.5 rounded-xl font-medium text-white focus:ring-4 border-none">Disabled</Button>
          </div>
          <div class="w-full pb-4">
            <Button :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="flex items-center justify-center w-full px-5 py-2.5 rounded-xl font-medium text-white focus:ring-4 border-none">
              <Icon :p="{ src: 'mdi:cart' }" cl="me-1" />
              Buy Now
            </Button>
          </div>
          <div class="flex items-center justify-between w-full pb-4 gap-4">
            <Button :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="flex items-center justify-center rounded-full w-12 h-12 aspect-square font-medium text-white focus:ring-4 border-none">
              <Icon :p="{ src: 'mdi:check' }" />
            </Button>
            <Button :class="`bg-[${colorPalette[600]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="flex items-center justify-center rounded-full w-12 h-12 aspect-square font-medium text-white focus:ring-4 border-none">
              <Icon :p="{ src: 'mdi:check' }" />
            </Button>
            <Button :p="{ disabled: true }" :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="flex items-center justify-center rounded-full w-12 h-12 aspect-square font-medium text-white focus:ring-4 border-none">
              <Icon :p="{ src: 'mdi:check' }" />
            </Button>
          </div>
        </div>
      </div>

      <div class="rounded-xl p-4 flex flex-col items-start border relative overflow-hidden col-span-2">
        <p class="shrink-0 z-10">
          Other Components
        </p>
        <div class="w-full py-2">
          <Tabs v-bind="{ p:{'aid':896,}}" cl="mt-2 border-b border-gray-200" v-model="selectedTab">
            <TabsHeader cl="flex flex-wrap -mb-px text-sm font-medium text-center" v-bind="{p:{'aid':897,'id':'example',}}">
              <Tab cl="me-1" v-bind="{p:{'aid':898,'id':'tab1',}}">
                <Button v-bind="{p:{'aid':899,}}" :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="group me-0 text-white rounded-t-lg active dark:text-white inline-flex items-center leading-none border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 dark:border-gray-600 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                  <Text v-bind="{p:{'aid':900,'val':'Tab One',}}"></Text>
                </Button>
              </Tab>
              <Tab cl="me-1" v-bind="{p:{'aid':901,'id':'tab2',}}">
                <Button v-bind="{p:{'aid':902,}}" :class="`bg-[${colorPalette[500]}] hover:bg-[${colorPalette[600]}] focus:ring-[${colorPalette[300]}] data-[active=true]:bg-[${colorPalette[600]}] dark:data-[active=true]:bg-[${colorPalette[800]}]`" cl="group me-0 text-white rounded-t-lg active dark:text-white inline-flex items-center leading-none border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-normal text-sm px-5 py-2.5 dark:border-gray-600 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                  <Text v-bind="{p:{'aid':903,'val':'Tab Two',}}"></Text>
                </Button>
              </Tab>
            </TabsHeader>
          </Tabs>
          <TabsBody v-bind="{p:{'aid':910,'id':'example',}}">
            <TabContent cl="hidden p-4 rounded-lg bg-gray-50" v-bind="{p:{'aid':911,'id':'tab1',}}">
              <Text v-bind="{p:{'aid':912,'val':'Tab One content',}}"></Text>
            </TabContent>
            <TabContent cl="hidden p-4 rounded-lg bg-gray-50" v-bind="{p:{'aid':913,'id':'tab2',}}">
              <Text v-bind="{p:{'aid':914,'val':'Tab Two content',}}"></Text>
            </TabContent>
          </TabsBody>
        </div>
        <div class="w-full py-2">
          <Accordion v-bind="{p:{'aid':243,'opt':'open'}}" v-model="selectedAccordion">
            <AccordionHeader :cl="`flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 data-[active=true]:bg-[${colorPalette[100]}] data-[active=true]:text-[${colorPalette[500]}] data-[active=true]:hover:bg-[${colorPalette[200]}] focus:data-[active=true]:ring-[${colorPalette[200]}]`" v-bind="{p:{'aid':244,'id':'accordion2',}}">
              <Text v-bind="{p:{'aid':245,'val':'Accordion',}}"></Text>
            </AccordionHeader>
            <AccordionBody v-bind="{ p:{'aid':246,'id':'accordion2',} }" cl="p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 w-full">
              <Text v-bind="{p:{'aid':247,'val':'Accordion content',}}"></Text>
            </AccordionBody>
          </Accordion>
        </div>
      </div>

    </div>

  </div>
</template>
