
    <template>
      <div v-bind="a.p(p.p)" :class="a.c(p.cl)">
        <div class="relative mx-auto bg-gray-800 dark:bg-gray-700 rounded-t-[2.5rem] h-[63px] max-w-[133px]"></div>
        <div class="relative mx-auto border-gray-900 dark:bg-gray-800 dark:border-gray-800 border-[10px] rounded-[2.5rem] h-[213px] w-[208px]">
          <div class="h-[41px] w-[6px] bg-gray-800 dark:bg-gray-800 absolute -end-[16px] top-[40px] rounded-e-lg"></div>
          <div class="h-[32px] w-[6px] bg-gray-800 dark:bg-gray-800 absolute -end-[16px] top-[88px] rounded-e-lg"></div>
          <div class="rounded-[2rem] overflow-hidden h-[193px] w-[188px]">
            <slot></slot>
          </div>
        </div>
        <div class="relative mx-auto bg-gray-800 dark:bg-gray-700 rounded-b-[2.5rem] h-[63px] max-w-[133px]"></div>
      </div>
    </template>
    <script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
</script>
  