
    <template>
      <img v-if="s" v-bind="a.p(p.p)" :src="s" :class="a.c(p.cl)" :data-tooltip-target="tt" :data-tooltip-placement="tp" />
      <div v-else v-bind="a.p(p.p)" :class="a.c(p.cl)" :data-tooltip-target="tt" :data-tooltip-placement="tp">
        <slot></slot>
      </div>
    </template>
    <script setup>
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({p: {type: Object}, cl: {type: String}})
    const s = p.p?.src ? p.p.src : false
    const tt = p.p?.tipid ? p.p?.tipid : undefined
    const tp = p.p?.tipplacement ? p.p?.tipplacement : undefined
    </script>
  