export { classPrecedence }

const prefix = src => {
  let idx = src.lastIndexOf(':')
  return idx !== -1 ? src.substring(0, idx + 1) : ''
}

const exclude = (pfx, cls, clss) => {
  if (clss === undefined) return false;
  for (let i = 0; i < clss.length; i+= 1) {
    if (cls.indexOf(`${pfx}${clss[i]}`) !== -1) {
      // console.log('excluded', `${pfx}${clss[i]}`, cls)
      return true
    }
  }
  return false
}

const remove = (src, set, clss, xclss) => {
  // console.log('remove', src, clss)
  let pfx = prefix(src)
  for (let i = 0; i < set.length; i += 1) {
    let cls = set[i]
    for (let c = 0; c < clss.length; c += 1) {
      if (cls !== 'false') {
        let match = `${pfx}${clss[c]}`
        if (match.endsWith('-') && cls.startsWith(match)) {
          if (!exclude(pfx, cls, xclss)) {
            // console.log('removed', set[i])
            set[i] = 'false'
          }
        } else if (cls === match) {
          // console.log('removed', set[i])
          set[i] = 'false'
        }
      }
    }
  }
  return set
}

const TextExceptions = ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl', 'text-left', 'text-center', 'text-right', 'text-justify']

const Matchers = {

  // Spacing
  'p-':  (src, set) => remove(src, set, ['p-', 'px-', 'py-']),
  'px-': (src, set) => remove(src, set, ['p-', 'px-', 'pl-', 'pr-']),
  'py-': (src, set) => remove(src, set, ['p-', 'py-', 'pt-', 'pb-']),
  'pt-': (src, set) => remove(src, set, ['p-', 'py-', 'pt-']),
  'pr-': (src, set) => remove(src, set, ['p-', 'px-', 'pr-']),
  'pb-': (src, set) => remove(src, set, ['p-', 'py-', 'pb-']),
  'pl-': (src, set) => remove(src, set, ['p-', 'px-', 'pl-']),

  'm-':  (src, set) => remove(src, set, ['m-', 'mx-', 'my-']),
  'mx-': (src, set) => remove(src, set, ['m-', 'mx-', 'ms-', 'me-', 'ml-', 'mr-']),
  'my-': (src, set) => remove(src, set, ['m-', 'my-', 'mt-', 'mb-']),
  'ms-': (src, set) => remove(src, set, ['m-', 'ms-', 'ml-']),
  'me-': (src, set) => remove(src, set, ['m-', 'me-', 'mr-']),
  'mt-': (src, set) => remove(src, set, ['m-', 'mt-', 'my-']),
  'mr-': (src, set) => remove(src, set, ['m-', 'mr-', 'mx-']),
  'mb-': (src, set) => remove(src, set, ['m-', 'mb-', 'my-']),
  'ml-': (src, set) => remove(src, set, ['m-', 'mx-', 'ms-']),

  'space-x':  (src, set) => remove(src, set, ['space-x']),
  'space-y':  (src, set) => remove(src, set, ['space-y']),
  'gap-':     (src, set) => remove(src, set, ['gap-']),

  // Backgrounds
  'bg-':  (src, set) => remove(src, set, ['bg-'], ['bg-opacity-']),
  'bg-opacity':  (src, set) => remove(src, set, ['bg-opacity-']),

  // Texts
  'text-':     (src, set) => remove(src, set, ['text-'], TextExceptions),
  'text-xs':   (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-sm':   (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-base': (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-lg':   (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-xl':   (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-2xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-3xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-4xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-5xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-6xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-7xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),
  'text-8xl':  (src, set) => remove(src, set, ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']),

  'font-': (src, set) => remove(src, set, ['font-thin', 'font-extralight', 'font-light', 'font-normal', 'font-medium', 'font-semibold', 'font-bold', 'font-extrabold', 'font-black']),

  'text-left':    (src, set) => remove(src, set, ['text-left', 'text-center', 'text-right', 'text-justify']),
  'text-center':  (src, set) => remove(src, set, ['text-left', 'text-center', 'text-right', 'text-justify']),
  'text-right':   (src, set) => remove(src, set, ['text-left', 'text-center', 'text-right', 'text-justify']),
  'text-justify': (src, set) => remove(src, set, ['text-left', 'text-center', 'text-right', 'text-justify']),
  'text-current': (src, set) => remove(src, set, ['text-current', 'text-inherit', 'text-'], TextExceptions),
  'text-inherit': (src, set) => remove(src, set, ['text-current', 'text-inherit', 'text-'], TextExceptions),

  // Borders
  'ring-':         (src, set) => remove(src, set, ['ring-'], ['ring-opacity-', 'ring-offset-', 'ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset' ]),
  'ring-opacity-': (src, set) => remove(src, set, ['ring-opacity-']),
  'ring-offset-':  (src, set) => remove(src, set, ['ring-offset-']),
  'ring-offset-0': (src, set) => remove(src, set, ['ring-offset-0', 'ring-offset-1', 'ring-offset-2', 'ring-offset-4', 'ring-offset-8']),
  'ring-offset-1': (src, set) => remove(src, set, ['ring-offset-0', 'ring-offset-1', 'ring-offset-2', 'ring-offset-4', 'ring-offset-8']),
  'ring-offset-2': (src, set) => remove(src, set, ['ring-offset-0', 'ring-offset-1', 'ring-offset-2', 'ring-offset-4', 'ring-offset-8']),
  'ring-offset-4': (src, set) => remove(src, set, ['ring-offset-0', 'ring-offset-1', 'ring-offset-2', 'ring-offset-4', 'ring-offset-8']),
  'ring-offset-8': (src, set) => remove(src, set, ['ring-offset-0', 'ring-offset-1', 'ring-offset-2', 'ring-offset-4', 'ring-offset-8']),
  'ring-0':        (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring-1':        (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring-2':        (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring-4':        (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring-8':        (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring-inset':    (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),
  'ring':          (src, set) => remove(src, set, ['ring-0', 'ring-1', 'ring-2', 'ring-4', 'ring-8', 'ring-inset', 'ring']),

  'border':      (src, set) => remove(src, set, ['border']),
  'border-':     (src, set) => remove(src, set, ['border-']),
  'border-none': (src, set) => remove(src, set, ['border-none', 'border', 'border-']),
  'border-t':    (src, set) => remove(src, set, ['border-t', 'border']),
  'border-r':    (src, set) => remove(src, set, ['border-r', 'border']),
  'border-b':    (src, set) => remove(src, set, ['border-b', 'border']),
  'border-l':    (src, set) => remove(src, set, ['border-l', 'border']),
  'border-t-':   (src, set) => remove(src, set, ['border-t-']),
  'border-r-':   (src, set) => remove(src, set, ['border-r-']),
  'border-b-':   (src, set) => remove(src, set, ['border-b-']),
  'border-0':    (src, set) => remove(src, set, ['border-0', 'border-2', 'border-4', 'border-8', 'border']),
  'border-2':    (src, set) => remove(src, set, ['border-0', 'border-2', 'border-4', 'border-8', 'border']),
  'border-4':    (src, set) => remove(src, set, ['border-0', 'border-2', 'border-4', 'border-8', 'border']),
  'border-8':    (src, set) => remove(src, set, ['border-0', 'border-2', 'border-4', 'border-8', 'border']),

  'rounded':      (src, set) => remove(src, set, ['rounded']),
  'rounded-full': (src, set) => remove(src, set, ['rounded-full', 'rounded', 'rounded-']),
  'rounded-none': (src, set) => remove(src, set, ['rounded-none', 'rounded', 'rounded-']),
  'rounded-t-':   (src, set) => remove(src, set, ['rounded-t-', 'rounded-']),
  'rounded-r-':   (src, set) => remove(src, set, ['rounded-r-']),
  'rounded-b-':   (src, set) => remove(src, set, ['rounded-b-', 'rounded-']),
  'rounded-l-':   (src, set) => remove(src, set, ['rounded-l-']),

  // Flexbox
  'basis-':        (src, set) => remove(src, set, ['basis-']),
  'flex-row':      (src, set) => remove(src, set, ['flex-row', 'flex-col', 'grid', 'grid-', 'auto-', 'cols-', 'row-']),
  'flex-col':      (src, set) => remove(src, set, ['flex-row', 'flex-col', 'grid', 'grid-', 'auto-', 'cols-', 'row-']),
  'flex-grow':     (src, set) => remove(src, set, ['flex-grow', 'flex-grow-0', 'flex-shrink', 'flex-shrink-0']),
  'flex-grow-0':   (src, set) => remove(src, set, ['flex-grow', 'flex-grow-0', 'flex-shrink', 'flex-shrink-0']),
  'flex-shrink':   (src, set) => remove(src, set, ['flex-grow', 'flex-grow-0', 'flex-shrink', 'flex-shrink-0']),
  'flex-shrink-0': (src, set) => remove(src, set, ['flex-grow', 'flex-grow-0', 'flex-shrink', 'flex-shrink-0']),
  'inline-flex':   (src, set) => remove(src, set, ['inline-flex']),

  'justify-':       (src, set) => remove(src, set, ['justify-'], ['justify-items-', 'justify-self-']),
  'justify-items-': (src, set) => remove(src, set, ['justify-items-']),
  'justify-self-':  (src, set) => remove(src, set, ['justify-self-']),
  'content-':       (src, set) => remove(src, set, ['content-']),
  'items-':         (src, set) => remove(src, set, ['items-']),
  'self-':          (src, set) => remove(src, set, ['self-']),

  // Grid
  'grid':        (src, set) => remove(src, set, ['flex-']),
  'grid-flow-':  (src, set) => remove(src, set, ['grid-flow-']),
  'grid-cols-':  (src, set) => remove(src, set, ['grid-cols-']),
  'auto-cols-':  (src, set) => remove(src, set, ['auto-cols-', 'cols-start-', 'cols-end-']),
  'auto-rows-':  (src, set) => remove(src, set, ['auto-rows-', 'rows-start-', 'row-end-']),
  'cols-start-': (src, set) => remove(src, set, ['cols-start-']),
  'cols-end-':   (src, set) => remove(src, set, ['cols-end-']),
  'cols-span-':  (src, set) => remove(src, set, ['cols-span-']),
  'row-span-':   (src, set) => remove(src, set, ['row-span-', 'row-auto', 'row-start-', 'row-end-']),
  'row-start-':  (src, set) => remove(src, set, ['row-start-', 'row-auto']),
  'row-end-':    (src, set) => remove(src, set, ['row-end-', 'row-auto']),

  // Sizing
  'w-':     (src, set) => remove(src, set, ['w-']),
  'min-w-': (src, set) => remove(src, set, ['min-w-']),
  'max-w-': (src, set) => remove(src, set, ['max-w-']),
  'h-':     (src, set) => remove(src, set, ['h-']),
  'min-h-': (src, set) => remove(src, set, ['min-h-']),
  'max-h-': (src, set) => remove(src, set, ['max-h-']),

  // Effects
  'shadow':       (src, set) => remove(src, set, ['shadow']),
  'shadow-':      (src, set) => remove(src, set, ['shadow-'], ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none']),
  'shadow-sm':    (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-md':    (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-lg':    (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-xl':    (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-2xl':   (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-inner': (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),
  'shadow-none':  (src, set) => remove(src, set, ['shadow-sm', 'shadow-md', 'shadow-lg', 'shadow-xl', 'shadow-2xl', 'shadow-inner', 'shadow-none', 'shadow']),

  'opacity-': (src, set) => remove(src, set, ['opacity-']),

  'overflow-hidden':  (src, set) => remove(src, set, ['overflow-hidden', 'overflow-visible']),
  'overflow-visible': (src, set) => remove(src, set, ['overflow-hidden', 'overflow-visible']),
}

const classPrecedence = function(ucl, dcl, show) {
  if (!ucl || ucl === '') return dcl
  if (ucl === dcl) return ucl
  let uset = ucl.split(' ')
  let dset = dcl.split(' ')
  let set = dset.map(el => el)
  for (let i = 0; i < uset.length; i += 1) {
    let cls = uset[i]
    for (let d = 0; d < set.length; d += 1) {
      if (set[d] === cls) {
        set[d] = 'false'
      }
    }
    let stop = false
    let pfx = prefix(cls)
    cls = cls.replace(pfx, '')
    while (!stop && cls.length > 1 && !cls.endsWith(':')) {
      stop = false
      if (Matchers[cls] !== undefined) {
        set = Matchers[cls](`${pfx}${cls}`, set)
        stop = true
      }
      cls = cls.substring(0, cls.length - 1)
    }
  }
  if (show) {
    console.log('classPrecendence ucl', ucl)
    console.log('classPrecendence dcl', dcl)
    console.log('result', `${ucl} ${set.filter(el => el !== 'false').join(' ')}`.trim())
    console.log('')
  }
  return `${ucl} ${set.filter(el => el !== 'false').join(' ')}`.trim()
}
