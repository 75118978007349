<template>
  <div ref="r" v-bind="a.p(p.p)" :class="a.c(p.cl)" role="status">
    <slot></slot>    
  </div>
</template>
<script setup>
import { ref, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const r = ref()
const addSpin = el => {
  if (!el.classList.contains('animate-spin')) el.classList.add('animate-spin')
}
onMounted(_ => {
  if (r.value && r.value.children.length > 0) addSpin(r.value.children[0])
})
onUpdated(_ => {
  if (r.value && r.value.children.length > 0) addSpin(r.value.children[0])
})
</script>