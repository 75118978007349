<script setup>

import { watch } from 'vue';


/* interface */

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'changed',
]);


const modelValue = defineModel({
  type: Array,
});

watch(modelValue, ()=> {
  emit('changed', modelValue.value);
}, { deep: true });

</script>


<template>
  <div class="py-2">
    <div class="space-y-2">

      <div class="flex gap-2">
        <p class="w-0 grow text-sm">
          Key
        </p>
        <p class="w-0 grow text-sm">
          Value
        </p>
        <div v-if="!props.readOnly" class="w-[36px]" />
      </div>

      <div
        v-for="num of (modelValue?.length ?? 0) + (props.readOnly ? 0 : 1)"
        class="flex gap-2">
        <input
          type="text"
          :key="`key-${num}`"
          placeholder="Key"
          :readonly="props.readOnly"
          class="w-0 grow px-4 pb-2 rounded-lg border-b border-gray-300 focus:ring-0 focus:outline-none focus:e-border-primary-500"
          :value="num < (modelValue?.length ?? 0) + 1 ? modelValue[num - 1].key : ''"
          @input="num < (modelValue?.length ?? 0) + 1 ? (modelValue[num - 1].key = $event.target.value) : (Array.isArray(modelValue) ? modelValue.push({ key: $event.target.value, value: '' }) : modelValue = [{ key: $event.target.value, value: '' }])"
        />
        <input
          type="text"
          :key="`value-${num}`"
          placeholder="Value"
          :readonly="props.readOnly"
          class="w-0 grow px-4 pb-2 rounded-lg border-b border-gray-300 focus:ring-0 focus:outline-none focus:e-border-primary-500"
          :value="num < (modelValue?.length ?? 0) + 1 ? modelValue[num - 1].value : ''"
          @input="num < (modelValue?.length ?? 0) + 1 ? (modelValue[num - 1].value = $event.target.value) : (Array.isArray(modelValue) ? modelValue.push({ key: '', value: $event.target.value }) : modelValue = [{ key: '', value: $event.target.value }])"
        />
        <Button
          v-if="!props.readOnly"
          :class="`${num < (modelValue?.length ?? 0) + 1 ? '' : 'invisible pointer-events-none'} bg-white e-text-primary-500 hover:e-bg-primary-600 hover:text-white focus:e-ring-primary-300 data-[active=true]:e-bg-primary-600 dark:data-[active=true]:e-bg-primary-800`"
          cl="block px-2.5 py-2.5 rounded-xl font-medium focus:ring-4 border-none"
          @click="modelValue = modelValue.filter((_, i) => i !== num - 1)">
          <Icon :p="{ src: 'mdi:trash-can-outline' }" cl="text-xl"/>
        </Button>
      </div>

    </div>
  </div>
</template>
