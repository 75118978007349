<template>
  <button ref="el" type="button" v-bind="apg.p(p.p)" :class="apg.c(dcl(p.cl))" :disabled="dsbld" :data-tooltip-target="dtt" :data-tooltip-placement="dtp" :data-dropdown-toggle="ddt" :data-dropdown-offset-distance="dof" data-dropdown-offset-skidding="0" @keydown="keydown">
    <p class="whitespace-nowrap truncate">{{showLabel}}</p>
    <Icon v-if="!p.p?.noChevron" cl="transition-all ms-1" :class="{ 'rotate-180': isDropdownOpened }" :p="{ src: 'mdi-chevron-down' }"/>
    <input v-if="p.p?.id" ref="hel" :id="p.p.id" type="hidden" v-model="m" />
  </button>
  <slot></slot>
</template>
<script setup>
import { ref, watch, computed, onMounted, onUpdated, provide } from 'vue'
import { useEventListener } from '../../composables/use-event-listener.js'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const m = defineModel()
provide('modelValue', m)
const apg = useAppeggio()
const orig = ref(p.modelValue)
const el = ref()
const label = ref('')
const showLabel = computed(_ => label.value || p.p?.label)
const isDsbld = computed(_ => p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(false)
const dcl = cl => dsbld.value ? cl + ' cursor-not-allowed opacity-50' : cl
const dtt = p.p?.tipid && !dsbld.value ? p.p?.tipid : undefined
const dtp = p.p?.tipplacement ? p.p?.tipplacement : undefined
const ddt = p.p?.id ? p.p.id + 'Dd' : undefined
const dof = ddt && p.p?.ddoffset !== undefined ? p.p.ddoffset : ddt ? '2' : undefined
const isDropdownOpened = ref(false)
provide('itemLabel', lbl => {
  label.value = lbl
})
watch(isDsbld, v => {
  dsbld.value = v
  if (v) {
    label.value = p.p?.label || ''
  }
})
watch(m, v => {
  if (p.p?.id && v === '') {
    label.value = p.p?.label || ''
  }
}, { immediate: true })
const keydown = $e => {
  if ($e.handled) return;
  if ($e.key === 'Escape') {
    $e.handled = true
    el.value.click()
  }
}
useEventListener('click', _ => {
  if (ddt) {
    const element = document.getElementById(ddt);
    if (element) {
      isDropdownOpened.value = !element.classList.contains('hidden');
      element.style.minWidth = (el.value?.offsetWidth || 'xxx') + 'px';
    }
  }
})
</script>