<template>
  <div id="pgver" :val="ver" />
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useAppeggio } from './useAppeggio'

const emit = defineEmits([ 'pgshn' ])

const apg = useAppeggio()
const updated = computed(() => apg.pageVersion())
const ver = ref('')

watch(updated, v => {
  setTimeout(() => {
    ver.value = v
    emit('pgshn', v)
  }, 200)
}, { immediate: true })
</script>
