<template>
  <div id="page-container">
    <slot />
  </div>
</template>

<script setup>
import './css/index.css'

import { computed, onMounted, watch } from 'vue'
import { useAppeggio } from '../app/useAppeggio.js'

const apg = useAppeggio()
// const updated = computed(() => apg.pageVersion())
const navhash = computed(() => apg.navhash())

onMounted(_ => apg.mounted())

// watch(updated, v => {
//   if (!apg.inBrowser()) return;
//   console.log('pgshell - onPageShown', v)
// }, { immediate: true })

watch(navhash, v => {
  if (!apg.inBrowser()) return;
  if (v) {
    console.log('pgshell - watch', v)
    let delay = 100
    if (apg.navparams()) {
      apg.pageTickle()
      delay = 150
    }
    setTimeout(() => apg.scrollTo(v), delay)
  }
}, { immediate: true })
</script>
