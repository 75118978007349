
    <template>
      <button type="button" v-bind="a.p(m(p.p))" class="rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:hover:bg-gray-700" :class="a.c(p.cl)" aria-label="Close" :data-dismiss-target="di">
        <span class="sr-only">Close</span>
        <slot name="prepend"></slot>
        <slot></slot>
        <slot name="append"></slot>
      </button>
    </template>
    <script setup>
    import { onMounted } from 'vue'
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
    const i = p.p?.id ? p.p.id : 'alertDismissButton' + p.p?.aid
    const m = v => Object.assign({}, v, { id: i })
    const di = p.p?.dismissid ? '#' + p.p.dismissid : '#alertDismissButton' + i
    onMounted(_ => a.fbInit('dismisses'))
    </script>
  