<template>
  <slot name="prepend"></slot>
  <input :id="i" type="range" v-model="m" v-bind="a.p(clean(p.p))" :class="a.c(dcl(p.cl))" :disabled="dsbld">
  <slot name="append"></slot>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number] } })
const i = p.p?.id ? p.p?.id : 'inputField' + p.p?.aid
const im = ref(p.modelValue === undefined ? p.p?.value || '' : p.modelValue)
const m = computed({
  get: _ => p.modelValue === undefined ? im.value : p.modelValue,
  set: v => {
    if (p.modelValue === undefined) im.value = v
    else emit('update:modelValue', v)
    console.log([v, im.value, p.modelValue])
  }
})
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
const dcl = cl => isDsbld() ? cl + ' cursor-not-allowed opacity-50' : cl
onUpdated(_ => {
  dsbld.value = isDsbld()
})
function clean(p) {
  const p1 = Object.assign({}, p)
  delete p1.value
  return p1
}
</script>