<template>
    <Alert v-if="inBrowser() && isOpen" cl="fixed bottom-[8px] left-1/2 -translate-x-1/2 z-[998] w-[500px] max-w-full flex flex-col items-start shadow border bg-[#353535] rounded-lg p-4">
      <Text :p="{ tk: 'CookiesMsgTitle', val: 'Cookies use' }" cl="font-secondary text-white text-xl" />
      <Text :p="{ tk: 'CookiesMsgMsg', val: 'We use cookies to improve your user experience, and for analytics.' }" cl="mt-4 font-secondary text-white text-base md:text-lg" />
      <div class="self-end">
        <Button :p="{ href: false }" cl="bg-primary-500 text-white px-4 py-2 rounded-lg" @click="accept">
          <Text :p="{ tk: 'CookiesMsgAccept', val: 'Accept' }" />
        </Button>
        <Button :p="{ href: false }" cl="ms-2 bg-transparent text-white px-4 py-2 rounded-lg" @click="deny">
          <Text :p="{ tk: 'CookiesMsgDeny', val: 'Deny' }" />
        </Button>
      </div>
    </Alert>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { setOptions, bootstrap } from 'vue-gtag'
import { useAppeggio } from './useAppeggio.js'
import { inBrowser } from './utils.js'

const app = useAppeggio()

const openable = ref(true)

function getKey() {
  return `GDPR:accepted:${app.host()}`
}

function getGDPR() {
  return localStorage.getItem(getKey())
}

const isOpen = computed(() => {
  let usesCookies = app.usesCookies() && !app.isFramed() && !app.isEdit()
  return usesCookies && getGDPR() === null && openable.value
})

function getAnalyticsId() {
  let id = app.googleAnalyticsId()
  return (id && id !== '') ? id : false
}

function turnOnAnalytics() {
  if (getGDPR() === 'yes') {
    let id = getAnalyticsId()
    let booted = app.getAnalyticsBooted()
    if (id && !booted) {
      setOptions({ config: { id } })
      app.setAnalyticsBooted(true)
      bootstrap().then((gtag) => {
        // all done!
        console.log('analytics booted', gtag)
      })
    }
  }
}

onMounted(() => {
  turnOnAnalytics()
})

function accept() {
  openable.value = false
  localStorage.setItem(getKey(), "yes")
  turnOnAnalytics()
}

function deny() {
  openable.value = false
  localStorage.setItem(getKey(), "no")
}
</script>
