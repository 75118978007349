<template>
  <li v-bind="a.p(p.p)" :class="[a.c(p.cl), 'cursor-pointer', timelineVertical ? 'ps-8 mb-6' : 'ps-6 mb-6 md:ps-0 md:mb-0 md:pt-6 md:me-10']" role="presentation" @click="click">
    <slot></slot>
  </li>
</template>
<script setup>
import { inject, computed, provide } from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const timelineVertical = inject('timelineVertical', false)
const m = inject('modelValue')
function click() {
  m.value = p.p?.value
}
const timelineValue = computed(_ => p.p?.value)
provide('timelineValue', timelineValue)
</script>