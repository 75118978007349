<template>
  <a v-bind="a.p(p.p)" :class="a.c(dcl(p.cl))" :target="p.p.target ? p.p.target : undefined" :disabled="dsbld" :data-tooltip-target="tt" :data-tooltip-placement="tp" :data-popover-target="popi">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </a>
</template>
<script setup>
import { ref, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const h = p.p?.type === 'submit' || p.p?.modal || p.p?.ddid ? undefined : p.p?.href
const tt = p.p?.tipid ? p.p?.tipid : undefined
const tp = p.p?.tipplacement ? p.p?.tipplacement : undefined
const d = p.p?.ddid ? p.p.ddid + 'Dd' : undefined
const o = p.p?.ddid && p.p?.ddoffset !== undefined ? p.p.ddoffset : p.p.ddid ? '2' : undefined
const popi = p.p?.popi ? p.p?.popi : undefined
onMounted(_ => {
  if (popi !== undefined) a.fbInit('popovers')
})
const r = ref()
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
const dcl = cl => isDsbld() ? cl + ' cursor-not-allowed opacity-50' : cl 
onUpdated(_ => {
  dsbld.value = isDsbld()
})
</script>