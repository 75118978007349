<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)">
    <Icon v-for="i in p.p?.count" :key="i" :p="{ src: (m >= i) ? (p.p.fullIcon || 'mdi-star') : (p.p.emptyIcon || 'mdi-star-outline') }" @click="selectRating(i)" class="cursor-pointer" />
    <input type="hidden" :value="m" :id="p.p?.id" :name="p.p?.id" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const m = defineModel()
function selectRating(i) {
  if (m.value === 1 && i === 1) {
    m.value = 0;
  }
  else {
    m.value = i;
  }
}
</script>