<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)" :data-accordion="d" data-active-classes="unset" data-inactive-classes="unset">
    <slot></slot>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, provide } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const d = p.p?.opt === 'open' ? 'open' : 'collapse'
onMounted(_ => a.fbInit('accordions'))
const innerModel = ref(p.modelValue || '')

const m = computed(_ => {
  return p.modelValue !== undefined && p.modelValue !== false ? p.modelValue : innerModel.value
})

provide('a-get-accitem', m)
provide('a-set-accitem', v => {
  if (p.modelValue === undefined || p.modelValue === false) innerModel.value = v
  else emit('update:modelValue', v)
})
</script>