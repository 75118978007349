import Page from './Page.vue'
import ExcelTable from './ExcelTable.vue'
import ExcelColumn from './ExcelColumn.vue'
import Loop from './Loop.vue'
import Dump from './Dump.vue'
import Text from './Text.vue'
import Pusher from './Pusher.vue'
import PositionRelative from './PositionRelative.vue'
import Group from './Group.vue'
import Grid from './Grid.vue'
import GridItem from './GridItem.vue'
import Section from './Section.vue'
import Icon from './Icon.vue'
import ClipboardCopy from './ClipboardCopy.vue'
import Placeholder from './Placeholder.vue'
import Audio from './Audio.vue'
import Divider from './Divider.vue'
import Accordion from './Accordion.vue'
import AccordionHeader from './AccordionHeader.vue'
import AccordionBody from './AccordionBody.vue'
import Alert from './Alert.vue'
import AlertDismissButton from './AlertDismissButton.vue'
import Avatar from './Avatar.vue'
import AvatarIndicator from './AvatarIndicator.vue'
import AvatarStack from './AvatarStack.vue'
import Badge from './Badge.vue'
import BadgeDismissButton from './BadgeDismissButton.vue'
import Banner from './Banner.vue'
import BannerDismissButton from './BannerDismissButton.vue'
import BottomNavigation from './BottomNavigation.vue'
import BottomNavigationInner from './BottomNavigationInner.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import BreadcrumbsInner from './BreadcrumbsInner.vue'
import BreadcrumbItem from './BreadcrumbItem.vue'
import BreadcrumbSeparator from './BreadcrumbSeparator.vue'
import Button from './Button.vue'
import Link from './Link.vue'
import ButtonGroup from './ButtonGroup.vue'
import Select from './Select.vue'
import Card from './Card.vue'
import CardImage from './CardImage.vue'
import CardBody from './CardBody.vue'
import CardActions from './CardActions.vue'
import Carousel from './Carousel.vue'
import CarouselWrapper from './CarouselWrapper.vue'
import CarouselItem from './CarouselItem.vue'
import CarouselIndicatorWrapper from './CarouselIndicatorWrapper.vue'
import CarouselIndicator from './CarouselIndicator.vue'
import CarouselControlPrevious from './CarouselControlPrevious.vue'
import CarouselControlNext from './CarouselControlNext.vue'
import ChatBubble from './ChatBubble.vue'
import ChatBubbleContent from './ChatBubbleContent.vue'
import Col from './Col.vue'
import Container from './Container.vue'
import Mockup from './Mockup.vue'
import MockupIphone12 from './MockupIphone12.vue'
import MockupPixel from './MockupPixel.vue'
import MockupTablet from './MockupTablet.vue'
import MockupLaptop from './MockupLaptop.vue'
import MockupDesktop from './MockupDesktop.vue'
import MockupSmartwatch from './MockupSmartwatch.vue'
import Drawer from './Drawer.vue'
import Collapse from './Collapse.vue'
import CollapseTitle from './CollapseTitle.vue'
import CollapseContent from './CollapseContent.vue'
import Dropdown from './Dropdown.vue'
import Footer from './Footer.vue'
import Form from './Form.vue'
import Gallery from './Gallery.vue'
import Image from './Image.vue'
import Indicator from './Indicator.vue'
import Jumbotron from './Jumbotron.vue'
import Keyboard from './Keyboard.vue'
import ListGroup from './ListGroup.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import Modal from './Modal.vue'
import Navbar from './Navbar.vue'
import Pagination from './Pagination.vue'
import PaginationContainer from './PaginationContainer.vue'
import PaginationItem from './PaginationItem.vue'
import Popover from './Popover.vue'
import PopoverArrow from './PopoverArrow.vue'
import Progress from './Progress.vue'
import Ratings from './Ratings.vue'
import Row from './Row.vue'
import Sidebar from './Sidebar.vue'
import Skeleton from './Skeleton.vue'
import SpeedDial from './SpeedDial.vue'
import SpeedDialMenu from './SpeedDialMenu.vue'
import Spinner from './Spinner.vue'
import Stepper from './Stepper.vue'
import Step from './Step.vue'
import StepIcon from './StepIcon.vue'
import StepContent from './StepContent.vue'
import Table from './Table.vue'
import TableHeader from './TableHeader.vue'
import TableBody from './TableBody.vue'
import TableRow from './TableRow.vue'
import TableHead from './TableHead.vue'
import TableData from './TableData.vue'
import Tabs from './Tabs.vue'
import TabsHeader from './TabsHeader.vue'
import TabsBody from './TabsBody.vue'
import Tab from './Tab.vue'
import TabContent from './TabContent.vue'
import Timeline from './Timeline.vue'
import TimelineItem from './TimelineItem.vue'
import TimelineIcon from './TimelineIcon.vue'
import TimelineContent from './TimelineContent.vue'
import Toast from './Toast.vue'
import Tooltip from './Tooltip.vue'
import Article from './Article.vue'
import Video from './Video.vue'
import InputField from './InputField.vue'
import FileInput from './FileInput.vue'
import SearchInput from './SearchInput.vue'
import NumberInput from './NumberInput.vue'
import PhoneInput from './PhoneInput.vue'
import SelectList from './SelectList.vue'
import Textarea from './Textarea.vue'
import Timepicker from './Timepicker.vue'
import Checkbox from './Checkbox.vue'
import Radio from './Radio.vue'
import Toggle from './Toggle.vue'
import RangeInput from './RangeInput.vue'
import Label from './Label.vue'
import FloatingLabel from './FloatingLabel.vue'
import Blockquote from './Blockquote.vue'
import Datepicker from './Datepicker.vue'
export { register }
function register(app) {
  app.component('Page', Page)
  app.component('ExcelTable', ExcelTable)
  app.component('ExcelColumn', ExcelColumn)
  app.component('Loop', Loop)
  app.component('Dump', Dump)
  app.component('Text', Text)
  app.component('Pusher', Pusher)
  app.component('PositionRelative', PositionRelative)
  app.component('Group', Group)
  app.component('Grid', Grid)
  app.component('GridItem', GridItem)
  app.component('Section', Section)
  app.component('Icon', Icon)
  app.component('ClipboardCopy', ClipboardCopy)
  app.component('Placeholder', Placeholder)
  app.component('Audio', Audio)
  app.component('Divider', Divider)
  app.component('Accordion', Accordion)
  app.component('AccordionHeader', AccordionHeader)
  app.component('AccordionBody', AccordionBody)
  app.component('Alert', Alert)
  app.component('AlertDismissButton', AlertDismissButton)
  app.component('Avatar', Avatar)
  app.component('AvatarIndicator', AvatarIndicator)
  app.component('AvatarStack', AvatarStack)
  app.component('Badge', Badge)
  app.component('BadgeDismissButton', BadgeDismissButton)
  app.component('Banner', Banner)
  app.component('BannerDismissButton', BannerDismissButton)
  app.component('BottomNavigation', BottomNavigation)
  app.component('BottomNavigationInner', BottomNavigationInner)
  app.component('Breadcrumbs', Breadcrumbs)
  app.component('BreadcrumbsInner', BreadcrumbsInner)
  app.component('BreadcrumbItem', BreadcrumbItem)
  app.component('BreadcrumbSeparator', BreadcrumbSeparator)
  app.component('Button', Button)
  app.component('Link', Link)
  app.component('ButtonGroup', ButtonGroup)
  app.component('Select', Select)
  app.component('Card', Card)
  app.component('CardImage', CardImage)
  app.component('CardBody', CardBody)
  app.component('CardActions', CardActions)
  app.component('Carousel', Carousel)
  app.component('CarouselWrapper', CarouselWrapper)
  app.component('CarouselItem', CarouselItem)
  app.component('CarouselIndicatorWrapper', CarouselIndicatorWrapper)
  app.component('CarouselIndicator', CarouselIndicator)
  app.component('CarouselControlPrevious', CarouselControlPrevious)
  app.component('CarouselControlNext', CarouselControlNext)
  app.component('ChatBubble', ChatBubble)
  app.component('ChatBubbleContent', ChatBubbleContent)
  app.component('Col', Col)
  app.component('Container', Container)
  app.component('Mockup', Mockup)
  app.component('MockupIphone12', MockupIphone12)
  app.component('MockupPixel', MockupPixel)
  app.component('MockupTablet', MockupTablet)
  app.component('MockupLaptop', MockupLaptop)
  app.component('MockupDesktop', MockupDesktop)
  app.component('MockupSmartwatch', MockupSmartwatch)
  app.component('Drawer', Drawer)
  app.component('Collapse', Collapse)
  app.component('CollapseTitle', CollapseTitle)
  app.component('CollapseContent', CollapseContent)
  app.component('Dropdown', Dropdown)
  app.component('Footer', Footer)
  app.component('Form', Form)
  app.component('Gallery', Gallery)
  app.component('Image', Image)
  app.component('Indicator', Indicator)
  app.component('Jumbotron', Jumbotron)
  app.component('Keyboard', Keyboard)
  app.component('ListGroup', ListGroup)
  app.component('List', List)
  app.component('ListItem', ListItem)
  app.component('Modal', Modal)
  app.component('Navbar', Navbar)
  app.component('Pagination', Pagination)
  app.component('PaginationContainer', PaginationContainer)
  app.component('PaginationItem', PaginationItem)
  app.component('Popover', Popover)
  app.component('PopoverArrow', PopoverArrow)
  app.component('Progress', Progress)
  app.component('Ratings', Ratings)
  app.component('Row', Row)
  app.component('Sidebar', Sidebar)
  app.component('Skeleton', Skeleton)
  app.component('SpeedDial', SpeedDial)
  app.component('SpeedDialMenu', SpeedDialMenu)
  app.component('Spinner', Spinner)
  app.component('Stepper', Stepper)
  app.component('Step', Step)
  app.component('StepIcon', StepIcon)
  app.component('StepContent', StepContent)
  app.component('Table', Table)
  app.component('TableHeader', TableHeader)
  app.component('TableBody', TableBody)
  app.component('TableRow', TableRow)
  app.component('TableHead', TableHead)
  app.component('TableData', TableData)
  app.component('Tabs', Tabs)
  app.component('TabsHeader', TabsHeader)
  app.component('TabsBody', TabsBody)
  app.component('Tab', Tab)
  app.component('TabContent', TabContent)
  app.component('Timeline', Timeline)
  app.component('TimelineItem', TimelineItem)
  app.component('TimelineIcon', TimelineIcon)
  app.component('TimelineContent', TimelineContent)
  app.component('Toast', Toast)
  app.component('Tooltip', Tooltip)
  app.component('Article', Article)
  app.component('Video', Video)
  app.component('InputField', InputField)
  app.component('FileInput', FileInput)
  app.component('SearchInput', SearchInput)
  app.component('NumberInput', NumberInput)
  app.component('PhoneInput', PhoneInput)
  app.component('SelectList', SelectList)
  app.component('Textarea', Textarea)
  app.component('Timepicker', Timepicker)
  app.component('Checkbox', Checkbox)
  app.component('Radio', Radio)
  app.component('Toggle', Toggle)
  app.component('RangeInput', RangeInput)
  app.component('Label', Label)
  app.component('FloatingLabel', FloatingLabel)
  app.component('Blockquote', Blockquote)
  app.component('Datepicker', Datepicker)
}
